@import "definitions";

.dashboard {

    padding:2em;

    .dashlet {
        width:100%;
        background-color: #fff;
        padding: 1em;
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        margin: 0 0 1em 0;
        box-sizing: border-box;
        box-shadow: 1px 1px 2px #aaaaaa;

        .dashlet_head {
            padding:.5em;
            .dashlet_head_label {
                margin:0;
            }
        }

        .dashlet_inner {
            padding:1em;
        }
    }

    .dashlet_container {
        &.dashlet_container_type_horizontal {
            display:flex;
            &>div {
                flex:1;
                //width:100%;
                display:flex;

                margin-right:1em;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    h3 {
        margin-top:0;
    }

    .module_search_dashlet {
       form {
           display:flex;
           button {
               margin-left:1em;
           }
       }
    }

    .order_statistics_dashlet {
        .custom_date_chooser {
            display:flex;
            & > div {
                margin-left:1em;
            }
        }
    
    }
}